"use strict";

(function($){
	// Handle menu resize.
	$(window).on("resize load", function(e) {
		if(e.type === "resize") {
			let headerHeight = $(".site__header").height();
			$("body").css("padding-top", headerHeight);
		}

		if($(window).width() > 992) {
			if($(".fl-menu-mobile-toggle").hasClass("fl-menu-mobile-toggle--active")) {
				$(".fl-menu-mobile-toggle").removeClass("fl-menu-mobile-toggle--active");
			}
		}
	});
})(jQuery);
